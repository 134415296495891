<template>
    <div id="chartdiv10"></div>
</template>

<script>
    import * as am4core from "@amcharts/amcharts4/core";
    import * as am4charts from "@amcharts/amcharts4/charts";
    import am4themes_animated from "@amcharts/amcharts4/themes/animated";
    import API from '@/api'

    export default {
        props: ['date_from', 'date_to', 'projectID'],
        data() {
            return {
                form: {
                    date_from: '',
                    date_to: '',
                    client_id: '',
                    projectID: ''
                },
                datacollection: {
                    labels: [],
                    datasets: [{
                        data: [],
                    }]
                },
            }
        },
        beforeMount() {
            this.form.date_from = this.date_from;
            this.form.date_to = this.date_to;
            this.selected_client_id = JSON.parse(localStorage.getItem('client_id'));
            this.form.client_id = this.selected_client_id;
            this.getChartData();
        },
        mounted() {
            this.form.projectID = this.projectID;
        },
        created() {
            // this.$EventBus.$on("filterByDate", (data) => {
            //     this.getFormData(data);
            // });
            // this.$EventBus.$on("resetForm", (data) => {
            //     this.getFormData(data);
            // });
        },
        watch: {
            projectID(newProjectID){
                if(newProjectID){
                    this.getChartData();
                }
            }
        },
        methods: {
            getFormData(data) {
                this.form.date_from = data.date_from;
                this.form.date_to = data.date_to;
                this.updateChartData()
            },
            getChartData() {
                this.form.date_from = this.$parent.form.date_from;   
                this.form.date_to = this.$parent.form.date_to;    
                this.form.projectID = this.projectID;
                API.getCategoryPerType(this.form,
                    data => {
                        var sportArray = data.data
                        console.log(data.data);
         
                        am4core.useTheme(am4themes_animated);
                        let chart = am4core.create("chartdiv10", am4charts.XYChart);
                        if(chart.logo){
                            chart.logo.disabled = true;
                        }
                        chart.data = sportArray;
                        console.log('ChartData',chart.data);
                        // Create axes

                        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                        categoryAxis.dataFields.category = "category_name";
                        categoryAxis.renderer.grid.template.location = -12;
                        categoryAxis.renderer.minGridDistance = 30;
                        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

                        // Create series
                        let series = chart.series.push(new am4charts.ColumnSeries());
                        series.dataFields.valueY = "spot_count";
                        series.dataFields.categoryX = "category_name";
                        series.name = "spot_count";
                        series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";
                        series.columns.template.fillOpacity = .8;

                        let columnTemplate = series.columns.template;
                        columnTemplate.strokeWidth = 2;
                        columnTemplate.strokeOpacity = 1;
                        chart.responsive.enabled = true;
                        
                    },
                    err => {
                    }
                )
            },
            updateChartData() {
                API.getCategoryPerType(this.form,
                    data => {
                        var sportArray = data.data         
                        am4core.useTheme(am4themes_animated);
                        let chart = am4core.create("chartdiv10", am4charts.XYChart);
                        if(chart.logo){
                            chart.logo.disabled = true;
                        }
                        chart.data = sportArray;
                        console.log('ChartData',chart.data);
                        // Create axes

                        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                        categoryAxis.dataFields.category = "category_name";
                        categoryAxis.renderer.grid.template.location = -12;
                        categoryAxis.renderer.minGridDistance = 20;
                        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

                        // Create series
                        let series = chart.series.push(new am4charts.ColumnSeries());
                        series.dataFields.valueY = "spot_count";
                        series.dataFields.categoryX = "category_name";
                        series.name = "spot_count";
                        series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";
                        series.columns.template.fillOpacity = .8;

                        let columnTemplate = series.columns.template;
                        columnTemplate.strokeWidth = 2;
                        columnTemplate.strokeOpacity = 1;
                        chart.responsive.enabled = true;
                    },
                    err => {
                    }
                )
            },
        }
    }
</script>

<style >
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

#chartdiv10{
  width: 100%;
  height: 330px;
}

</style>