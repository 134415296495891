<template>
  <b-navbar
    id="template-header"
    class="default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row"
    toggleable="lg"
  >
    <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
      <router-link class="navbar-brand brand-logo mr-4 ml-1" to="/">
        <img src="@/assets/images/watch-logo.png" alt="logo">
      </router-link>
      <router-link class="navbar-brand brand-logo-mini" to="/">
        <img src="@/assets/images/mini-logo.png" alt="logo">
      </router-link>
    </div>
    <div
      class="navbar-menu-wrapper d-flex align-items-center justify-content-between ml-auto ml-lg-0"
    >
      <button
        class="navbar-toggler navbar-toggler align-self-center d-lg-block"
        type="button"
        @click="toggleSidebar()"
      >
        <span class="ti-layout-grid2"></span>
      </button>
      <div class="nav-search d-none d-lg-block">
        <div class="page-title">
          <h2>{{name}}</h2>
        </div>
      </div>
      <b-navbar-nav class="navbar-nav-right ml-auto new-ml-auto">
        <b-nav-item-dropdown right class="preview-list mr-1 d-none">
          <template slot="button-content">
            <div class="nav-link count-indicator dropdown-toggle">
              <i class="ti-email mx-0 d-none"></i>
            </div>
          </template>
          <h6 class="p-3 mb-0">Messages</h6>
          <b-dropdown-item class="preview-item">
            <div class="preview-thumbnail">
              <img src="https://via.placeholder.com/36X36" alt="image" class="profile-pic">
            </div>
            <div class="preview-item-content flex-grow">
              <h6 class="preview-subject ellipsis font-weight-normal">David Grey</h6>
              <p class="font-weight-light small-text text-muted mb-0">The meeting is cancelled</p>
            </div>
          </b-dropdown-item>
          <b-dropdown-item class="preview-item">
            <div class="preview-thumbnail">
              <img src="https://via.placeholder.com/36X36" alt="image" class="profile-pic">
            </div>
            <div class="preview-item-content flex-grow">
              <h6 class="preview-subject ellipsis font-weight-normal">Tim Cook</h6>
              <p class="font-weight-light small-text text-muted mb-0">New product launch</p>
            </div>
          </b-dropdown-item>
          <b-dropdown-item class="preview-item">
            <div class="preview-thumbnail">
              <img src="https://via.placeholder.com/36X36" alt="image" class="profile-pic">
            </div>
            <div class="preview-item-content flex-grow">
              <h6 class="preview-subject ellipsis font-weight-normal">Johnson</h6>
              <p class="font-weight-light small-text text-muted mb-0">Upcoming board meeting</p>
            </div>
          </b-dropdown-item>
        </b-nav-item-dropdown>
        <div class="lang-e3">
          <LanguageSwitcher v-if="permissionExist('change_translation')" user="admin"/>
        </div>
        <b-nav-item-dropdown right class="preview-list new-preview-list">
          <template slot="button-content">
            <div class="nav-link count-indicator dropdown-toggle btn-dropdown-toggle c-btn">
              <!--                            <i class="ti-bell mx-0"></i>-->
              <!--                            <span class="count"></span>-->
              <vue-snotify></vue-snotify>
              <b-dropdown 
                  v-if="isDashboardRoute && version_id == 2 && is_pro_sync == 1"
                  class="btn-primary mr-2" 
                  variant="primary" 
                  size="sm" 
                  :text="$t('Project (Survey Name)')" 
                  v-model="selectedProjectId">
                  <!-- Add the "Select" option -->
                  <b-dropdown-item
                    :key="'select'"
                    :active="selectedProjectId === 'select'"
                    @click="onProjectClick('select')"
                  >
                    {{ $t("All Data") }}
                  </b-dropdown-item>

                  <!-- Render the projects -->
                  <b-dropdown-item
                      v-for="project in projectOptions"
                      :key="project.id"
                      :active="project.id === selectedProjectId"
                      @click="onProjectClick(project.id)"
                    >
                    {{ project.name }}
                  </b-dropdown-item>
              </b-dropdown>
              <button @click="translateData()" type="button" v-if="permissionExist('change_translation')"
                disabled
                class="btn btn-primary btn-sm mr-2">
                  <span>{{ $t("translate-data") }}</span>
                <div v-if="isLoading" class="spinner-border spinner-border-sm ml-2" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
              </button>
              <b-dropdown class="btn-primary mr-2" variant="primary" size="sm" :text="$t('change-project')" v-if="user_clients_count > 1">
                <b-dropdown-item
                  v-for="client of clientArr"
                  :key="client.id"
                  @click="changeClient(client.value)"
                >{{client.text}}</b-dropdown-item>
              </b-dropdown>
              <button
                type="button"
                class="btn btn-primary btn-sm"
                v-if="super_admin_email"
                @click="loginAs"
              >{{ $t('back-to-super-admin') }}</button>
            </div>
          </template>
          <!--                    <h6 class="p-3 mb-0">Notifications</h6>-->
          <!--                    <b-dropdown-item class="preview-item">-->
          <!--                        <div class="preview-thumbnail">-->
          <!--                            <div class="preview-icon bg-success">-->
          <!--                                <i class="ti-info-alt mx-0"></i>-->
          <!--                            </div>-->
          <!--                        </div>-->
          <!--                        <div class="preview-item-content">-->
          <!--                            <h6 class="preview-subject font-weight-normal">Application Error</h6>-->
          <!--                            <p class="font-weight-light small-text mb-0 text-muted">Just now</p>-->
          <!--                        </div>-->
          <!--                    </b-dropdown-item>-->
          <!--                    <b-dropdown-item class="preview-item">-->
          <!--                        <div class="preview-thumbnail">-->
          <!--                            <div class="preview-icon bg-warning">-->
          <!--                                <i class="ti-settings mx-0"></i>-->
          <!--                            </div>-->
          <!--                        </div>-->
          <!--                        <div class="preview-item-content">-->
          <!--                            <h6 class="preview-subject font-weight-normal">Settings</h6>-->
          <!--                            <p class="font-weight-light small-text mb-0 text-muted">Private message</p>-->
          <!--                        </div>-->
          <!--                    </b-dropdown-item>-->
          <!--                    <b-dropdown-item class="preview-item">-->
          <!--                        <div class="preview-thumbnail">-->
          <!--                            <div class="preview-icon bg-info">-->
          <!--                                <i class="ti-user mx-0"></i>-->
          <!--                            </div>-->
          <!--                        </div>-->
          <!--                        <div class="preview-item-content">-->
          <!--                            <h6 class="preview-subject font-weight-normal">New user registration</h6>-->
          <!--                            <p class="font-weight-light small-text mb-0 text-muted">2 days ago</p>-->
          <!--                        </div>-->
          <!--                    </b-dropdown-item>-->
        </b-nav-item-dropdown>
        <b-nav-item-dropdown right class="nav-profile">
          <template slot="button-content">
            <span
              class="nav-link dropdown-toggle"
              id="profileDropdown"
              href="javascript:void(0);"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              <div class="nav-profile-img">
                <img
                  v-if="admin_user['image'] !== null"
                  :src="this.$server_url + admin_user.image"
                  alt="image"
                >
                <img v-else src="https://via.placeholder.com/36X36" alt="image">
              </div>
            </span>
          </template>
          <b-dropdown-item class="preview-item" @click="goToSettings()">
            <!-- <router-link to="/settings"> -->
            <i class="ti-settings text-primary"></i> {{ $t('settings') }}
            <!-- </router-link> -->
          </b-dropdown-item>
          <b-dropdown-item class="preview-item" @click="logout()">
            <i class="ti-power-off text-primary"></i> {{ $t('logout') }}
          </b-dropdown-item>
        </b-nav-item-dropdown>
        <!--                <b-nav-item class="nav-logout d-none d-lg-block nav-settings">-->
        <!--                    <i class="ti-more" @click="togglesettingsPannel()"></i>-->
        <!--                </b-nav-item>-->
      </b-navbar-nav>
      <button
        class="navbar-toggler navbar-toggler-right align-self-center"
        type="button"
        @click="toggleMobileSidebar()"
      >
        <span class="mdi mdi-menu"></span>
      </button>
    </div>
  </b-navbar>
</template>

<script>
import LanguageSwitcher from '@/components/lang/LanguageSwitcher.vue';
import router from "../../router";
import API from "@/api";
import Vue from "vue";
import Snotify, {SnotifyPosition} from 'vue-snotify'
    const options = {
        toast: {
            position: SnotifyPosition.rightTop,
        }
    }
    Vue.use(Snotify, options)
export default {
  name: "app-header",
  data() {
    return {
      clientSettings: [],
      clientArr: [],
      clientFullArr: [],
      name: "",
      user_clients_count: 0,
      super_admin_email: "",
      admin_user: [],
      getPermissionsData: [],
      translation: [],
      isLoading:false,
      client_id: JSON.parse(localStorage.getItem("client_id")),
      projectOptions: [],
      selectedProjectId: null,
      version_id: null,
      is_pro_sync: null
    };
  },
  components: {
    LanguageSwitcher,
  },
  methods: {
    translateData() {
        this.isLoading = true;
        const data = {
            client_id: JSON.parse(localStorage.getItem('client_id')),
        }
        console.log('client_id :', data.client_id);
        API.translateData(
            data,
            data => {
                this.translation = data.data;
                if(data.status == 200 ){
                console.log('data.status :', data.status);
                    this.$snotify.success(data.message);
                }
                console.log('this.translation :', this.translation);
                this.isLoading = false;
            },
            err => {
                this.isLoading = false;
                if(err.data && err.status == 500){
                  console.log('ERROR',err);
                  console.log('Error response:', err);
                  console.log('Response status:', err.status);
                  console.log('Response data:', err.data.message);
                  this.$snotify.error(err.data.message);
                }
                else {
                  console.log('ERROR2',err)
                  this.$snotify.error('Failed to translate the data');
                }
            }
        )
  },
    getPermissions() {
      this.getPermissionsData = JSON.parse(localStorage.getItem('userPermissions'));
      console.log('this.getPermissionsData',this.getPermissionsData);
    },
    permissionExist(param) {
      var check = this.getPermissionsData.includes(param);
      if (check) {
          return true;
      } else {
          return false;
      }
    },
    toggleSidebar: () => {
      document.querySelector("body").classList.toggle("sidebar-icon-only");
      document.querySelector(".main-panel").classList.toggle("panel-width");
      document.querySelector("version-name").classList.toggle("showVersionName");
    },
    toggleMobileSidebar: () => {
      document.querySelector("#sidebar").classList.toggle("active");
    },
    togglesettingsPannel: () => {
      document.querySelector("#right-sidebar").classList.toggle("open");
    },
    goToSettings() {
      this.$router.push("/settings", () => {});
    },
    logout() {
      var admin_user = JSON.parse(localStorage.getItem("user"));
      var citizen_user = JSON.parse(
        localStorage.getItem("spotterClientSettings")
      );
      this.$store.commit('resetProjectIdInitialized');
      if (citizen_user) {
        if (citizen_user["user_id"] === admin_user["id"]) {
          this.$session.clear();
          localStorage.removeItem("userPermissions");
          localStorage.removeItem("isLoggedin");
          localStorage.removeItem("email");
          localStorage.removeItem("password");
          localStorage.removeItem("super_admin_email");
          localStorage.removeItem("remember_client_id");
          localStorage.removeItem("client_id");
          localStorage.removeItem("clientArr");
          localStorage.removeItem("user_clients_count");
          router.push("/");
        } else {
          API.logout(
            data => {
              localStorage.removeItem("super_admin_email");
              this.$session.clear();
              localStorage.removeItem("userPermissions");
              localStorage.removeItem("isLoggedin");
              localStorage.removeItem("email");
              localStorage.removeItem("password");
              localStorage.removeItem("remember_client_id");
              localStorage.removeItem("client_id");
              localStorage.removeItem("clientArr");
              localStorage.removeItem("user_clients_count");

              router.push("/");
            },
            err => {}
          );
        }
      } else {
        API.logout(
          data => {
            this.$session.clear();
            localStorage.removeItem("userPermissions");
            localStorage.removeItem("isLoggedin");
            localStorage.removeItem("email");
            localStorage.removeItem("password");
            localStorage.removeItem("remember_client_id");
            localStorage.removeItem("client_id");
            localStorage.removeItem("clientArr");
            localStorage.removeItem("user_clients_count");
            router.push("/");
          },
          err => {}
        );
      }
    },
    getClientSettings() {
      this.clientSettings = JSON.parse(localStorage.getItem("client_settings"));
      if (this.clientSettings) {
        this.name = this.clientSettings.name;
      }
    },
    loginAs() {
      let data = {
        email: this.super_admin_email
      };
      API.loginAs(
        data,
        data => {
          if (typeof data.token !== "undefined") {
            localStorage.removeItem("super_admin_email");
            localStorage.removeItem("client_id");
            localStorage.removeItem("user_clients_count");
            this.super_admin_email = "";
            localStorage.setItem("isLoggedin", data.token);
            localStorage.setItem(
              "client_settings",
              JSON.stringify(data.client_settings)
            );
            localStorage.setItem("user", JSON.stringify(data.user));
            localStorage.setItem(
              "userPermissions",
              JSON.stringify(data.permission)
            );
            router.push("/dashboard");
            router.go("/dashboard");
          }
        },
        err => {}
      );
    },
    changeClient(id) {
      var client = this.clientFullArr.find(o => o.client_id === id);
      localStorage.setItem("client_settings", JSON.stringify(client.clients));
      localStorage.setItem("remember_client_id", id);
      localStorage.setItem("client_id", id);
      this.getClientSettings();
      router.push("/dashboard");
      router.go("/dashboard");
    },
    updateLocalProfile(data) {
      this.admin_user['name'] = data.name;
      this.admin_user['image'] = data.image;
      console.log("user to be updated", this.admin_user);
      localStorage.setItem('user', JSON.stringify(this.admin_user));
    },
    getNames() {
      const data = {
        client_id: JSON.parse(localStorage.getItem("client_id")),
      };

      API.getProjectName(
        data,
        (response) => {
          this.version_id = response.version_id;
          this.is_pro_sync = response.is_pro_sync;
          this.projectOptions = response.data.map((project) => ({
            id: project.id,
            name: project.project_name,
            default_dashboard: project.default_dashboard,
          }));

          // Check for a project with default_dashboard = 1
          const defaultProject = this.projectOptions.find(
            (project) => project.default_dashboard == 1
          );

          // Set the selected project ID to the default project, or "select" if none exists
          this.selectedProjectId = defaultProject
            ? defaultProject.id
            : "select";

          if (this.selectedProjectId === "select") {
            this.resetDefaultDashboard();
          } else {
            this.onProjectClick(this.selectedProjectId);
          }
        },
        (err) => {
          console.error("Error fetching project names:", err);
        }
      );
    },

    onProjectClick(projectId) {
      this.selectedProjectId = projectId; 
      // Dispatch action to Vuex store to update project ID
      this.$store.dispatch('updateProjectID', projectId);
      if (projectId === "select") {
        // Reset default_dashboard for all projects
        this.resetDefaultDashboard();
      } else {
        // Set default_dashboard to 1 for the selected project
        this.updateProjectDefaultDashboard(projectId);
      }
    },
    updateProjectDefaultDashboard(projectId) {
      const data = {
        client_id: JSON.parse(localStorage.getItem('client_id')),
        project_id: projectId,
        default_dashboard: 1,
      };

      // Make API call to update the selected project's default_dashboard
      API.updateProjectDefaultDashboard(data, response => {

        // Set default_dashboard to 0 for all other projects
        this.updateOtherProjectsDefaultDashboard(projectId);
      }, err => {
        console.error('Error updating project default dashboard:', err);
      });
    },

    updateOtherProjectsDefaultDashboard(selectedProjectId) {
      this.projectOptions.forEach(project => {
        if (project.id !== selectedProjectId) {
          const data = {
            client_id: JSON.parse(localStorage.getItem('client_id')),
            project_id: project.id,
            default_dashboard: 0,
          };

          // Make API call to update the other projects
          API.updateProjectDefaultDashboard(data, response => {
          }, err => {
            console.error('Error updating other projects default dashboard:', err);
          });
        }
      });
    },
    resetDefaultDashboard() {
      this.projectOptions.forEach((project) => {
        const data = {
          client_id: JSON.parse(localStorage.getItem("client_id")),
          project_id: project.id,
          default_dashboard: 0,
        };

        // Make API call to reset default_dashboard for all projects
        API.updateProjectDefaultDashboard(data, (response) => {
        }, (err) => {
          console.error(`Error resetting default_dashboard for project ${project.id}:`, err);
        });
      });
    },
  },
  created() {
    this.$EventBus.$on("updateLocalProfile", data => {
      console.log("data in header", data);
      this.updateLocalProfile(data.data);
    });
  },
  beforeMount() {
    this.getClientSettings();
    var citizen_user = JSON.parse(
      localStorage.getItem("spotterClientSettings")
    );
    this.user_clients_count = JSON.parse(
      localStorage.getItem("user_clients_count")
    );
    this.clientFullArr = JSON.parse(localStorage.getItem("clientArr"));
    this.admin_user = JSON.parse(localStorage.getItem("user"));
    if (this.clientFullArr.length > 0) {
      this.clientFullArr.forEach((value, index) => {
        const data1 = {
          value: value.clients ? value.clients.id : "",
          text: value.clients ? value.clients.name : ""
        };
        this.clientArr.push(data1);
      });
    }
    console.log(this.user_clients_count);
    console.log(this.clientArr);
    this.super_admin_email = localStorage.getItem("super_admin_email");
  },
  mounted() {
    this.getPermissions();
    if (this.isDashboardRoute) {
      this.getNames();
    }
    if (this.clientSettings) {
      document.title = "WatchSpotter " + this.clientSettings.nick_name;
    } else {
      document.title = "WatchSpotter";
    }
  },
  computed: {
    isDashboardRoute() {
      return this.$route.path === '/dashboard';
    }
  },
  watch: {
    $route(to, from) {
      // If the route changes to '/dashboard', fetch the project data again
      if (to.path === '/dashboard') {
        this.getNames();
      }
    }
  },
  destroyed() {
    // this.$EventBus.$off("updateLocalProfile");
  }
};
</script>
<style scoped>
.dropdown .btn-group .b-dropdown .btn-primary {
  background-color: #0e4cfd;
}
@media (max-width: 1100px) {
  .page-title h2 {
    font-size: 22px;
  }
}
@media (max-width: 767px) {
  .new-ml-auto {
    margin-left: 0 !important;
    align-items: center !important;
  }
  .lang-e3 {
    display: flex;
    align-items: center;
  }
  .language-dropdown {
    margin-top: 0;
    display: flex;
  }
}

@media (max-width: 625px) {
  .navbar .navbar-menu-wrapper .navbar-nav.navbar-nav-right {
    flex-wrap: wrap !important;
    display: flex !important;
  }
  .new-preview-list {
    margin-right: 0 !important;
  }
  .btn-dropdown-toggle {
    display: flex;
    flex-direction: column;
  }
  .c-btn .btn-group{
    margin: 0 !important;
    margin-bottom: 3px !important;
  }
  .c-btn .btn-sm {
    margin-right: 0;
  }
}
@media (max-width: 375px) {
  .navbar.navbar-light .navbar-menu-wrapper {
    width: 88%;
  } 
  nav.navbar .navbar-brand-wrapper .brand-logo-mini img {
    width: 35px !important;
  }
  .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile img {
    width: 28px;
    height: 28px;
  }
}
</style>
